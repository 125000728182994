<template>
        <span v-if="shred === 'PENDING'" class="disabled">
            <Popper class="popperDark" arrow hover content="Pending for shred">
                <fa icon="trash-arrow-up" size="lg" class="text-grey me-1" />
            </Popper>
        </span>
        <span v-else-if="status === 'DRAFT'" class="isTooltipsLabel mx-1" :class="class">
            <Popper class="popperDark" arrow hover :content="status">
                <fa icon="file" size="lg" class="text-info me-1" />
            </Popper>
        </span>

        <span v-else-if="status === 'INVITED'" class="isTooltipsLabel me-2" :class="class">
            <Popper class="popperDark" arrow hover :content="status">
            <fa icon="envelope" size="lg" class="text-success" />
            </Popper>
        </span>

        <span v-else-if="status === 'SIGNED'" class="isTooltipsLabel me-1" :class="class">
            <Popper class="popperDark" arrow hover :content="status">
                <fa icon="signature" size="lg" class="text-success" />
            </Popper>
        </span>

        <span v-else-if="status === 'ALLSIGNED'" class="isTooltipsLabel me-1" :class="class">
            <Popper class="popperDark" arrow hover :content="status">
                <fa icon="check-double" size="lg" class="text-pink" />
            </Popper>
        </span>

        <span v-else-if="status === 'REVISE'" class="isTooltipsLabel ms-1 me-1" :class="class">
            <Popper class="popperDark" arrow hover :content="status">
                <fa icon="rotate-right" size="lg" class="text-orange" />
            </Popper>
        </span>

        <span v-else-if="status === 'VIEWONLY'" class="isTooltipsLabel ms-1 me-2" :class="class">
            <Popper class="popperDark" arrow hover :content="status">
                <fa icon="eye" size="lg" class="text-brown" />
            </Popper>
        </span>

</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'DocStatus',
    components: { Popper },
    props: [ 'status', 'class', 'shred' ],
    setup (props) {

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return {}
    }
}
</script>

<style>

</style>